import React, { Component, Fragment } from 'react';
import { footerHeight, mq } from '../css-variables';

export default class Footer extends Component {
  render() {
    const { language } = this.props;

    return (
      <Fragment>
        <footer className="footer">
          {language === 'ru'
            ? '© Мария Добина 2017-2023'
            : '© Maria Dobina 2017-2023'
          }
        </footer>

        {/*language=CSS*/}
        <style jsx>{`
          .footer {
            height: ${footerHeight};
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            display: block;
            color: black;
            text-align: center;
            line-height: 65px;
            font-size: 12px;
            color: gray;

            @media ${mq.xs} {
              font-size: 10px;
            }

            &::before, &::after {
              content: ' ';
              display: table;
            }
          }
        `}</style>
      </Fragment>
    );
  }
}
