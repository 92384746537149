import React, { Component, Fragment } from 'react';
import Link from 'next/link';
import Router from 'next/router';

import { mq } from '../css-variables';

let logoIdx = 0;

Router.onRouteChangeStart = () => {
  logoIdx++;

  if (logoIdx === 4) {
    logoIdx = 0;
  }
};

export default class Header extends Component {
  handleSwitchLanguage = () => {
    const { language, setLanguage } = this.props;

    if (language === 'ru') {
      setLanguage('en');
    } else {
      setLanguage('ru');
    }
  }

  handleShowAbout = () => {
    this.props.showAbout();
  }

  handleHideAbout = () => {
    this.props.hideAbout();
  }

  render() {
    const { language, isAbout, hideAbout } = this.props;

    const images = [
      '/static/images/call_hand.svg',
      '/static/images/like_hand.svg',
      '/static/images/draw_hand.svg',
      '/static/images/W_hand.svg'
    ];

    const preloadAboutImages = [
      '/static/pictures/about/photo1.jpg',
      '/static/pictures/about/photo2.jpg',
      '/static/pictures/about/photo3.jpg'
    ];

    return (
      <Fragment>
        <div style={{ width: '1px', height: '1px', opacity: '.0001', overflow: 'hidden', position: 'absolute' }}>
          {preloadAboutImages.map((image) => <img key={image} src={image} />)}
        </div>

        <header className="header">
          <div onClick={() => isAbout && hideAbout && hideAbout()}>
            <Link href="/">
              <a className="header__logo">
                <div className="header__logo__top">
                  <img src="/static/images/logo.svg" alt="Maria Dobina" />
                </div>
                <div className="header__logo__bottom">
                  <img src={images[logoIdx]} />
                </div>
              </a>
            </Link>
          </div>

          <div className="header__language" data-lang="ru" onClick={this.handleSwitchLanguage}>
            <span className="svg-hover">
              {language === 'ru'
                ? <Fragment>
                    <img src="/static/images/ENG_bt.svg" alt="" width="42" height="42" className="h1" />
                    <img src="/static/images/ENG_bt_hover.svg" alt="" width="42" height="42" className="h2" />
                  </Fragment>
                : <Fragment>
                    <img src="/static/images/RUS_bt.svg" alt="" width="42" height="42" className="h1" />
                    <img src="/static/images/RUS_bt_hover.svg" alt="" width="42" height="42" className="h2" />
                  </Fragment>
              }

            </span>
          </div>

          {isAbout
            ? <div
                className="header__info svg-hover"
                onClick={this.handleHideAbout}
              >
                <img src="/static/images/menu_round_bt.svg" alt="Info" width="42" height="42" className="header__info__img-info h1" />
                <img src="/static/images/menu_round_bt_hover.svg" alt="Info" width="42" height="42" className="header__info__img-info h2" />
              </div>
            : <div
                className="header__info svg-hover"
                onClick={this.handleShowAbout}
              >
                <img src="/static/images/i_bt.svg" alt="Info" width="42" height="42" className="header__info__img-info h1" />
                <img src="/static/images/i_bt_hover.svg" alt="Info" width="42" height="42" className="header__info__img-info h2" />
                <img src="/static/images/ghost_alone.svg" alt="Info" width="32" height="40" className="header__info__img-ghost" />
              </div>
          }
        </header>

        {/*language=CSS*/}
        <style jsx>{`
          .header {
            height: 135px;
            position: relative;

            @media ${mq.xs} {
              width: 100%;
              left: 0;
              top: 0;
              position: fixed;
              height: 80px;
              background-color: white;
              z-index: 10;
            }
          }

          .header__logo {
            top: 27px;
            position: absolute;
            width: 308px;
            left: 50%;
            margin-left: -154px;

            @media ${mq.xs} {
              top: 12.5px;
              width: 175px;
              margin-left: -87.5px;
            }
          }

          .header__logo__top {
            height: 28px;

            @media ${mq.xs} {
              height: 16px;
            }
          }

          .header__logo__bottom {
            height: 44px;
            width: 227px;
            margin-left: 40px;
            margin-top: 13px;

            @media ${mq.xs} {
              margin-top: 9px;
              width: 128px;
              height: 25px;
              margin-left: 22px;
            }
          }

          .header__language {
            cursor: pointer;
            position: absolute;
            top: 40px;
            left: 81px;

            @media ${mq.xs} {
              left: 12.5px;
              top: 26px;
              width: 32px;
              height: 32px;

              & img {
                width: 32px !important;
                height: 32px !important;
              }
            }
          }

          .header__info {
            cursor: pointer;
          }

          .header__info__img-info {
            position: absolute;
            top: 40px;
            right: 81px;

            @media ${mq.xs} {
              right: 12.5px;
              top: 26px;
              width: 32px !important;
              height: 32px !important;

              & img {
                width: 32px !important;
                height: 32px !important;
              }
            }
          }

          .header__info__img-ghost {
            position: absolute;
            top: 34px;
            right: 107px;

            @media ${mq.xs} {
              display: none;
            }
          }
        `}</style>
      </Fragment>
    );
  }
}
