import React, { Component } from 'react';
import cookies from 'next-cookies';

import data from '../data.json';
import Page from '../components/Page';
import CardsList from '../components/CardsList';

export default class extends Component {
  static async getInitialProps(ctx) {
    const { language } = cookies(ctx);

    return { language };
  }

  render() {
    return (
      <Page language={this.props.language}>
        <CardsList list={data.projects} />
      </Page>
    );
  }
}
