export default {
  footerHeight,
  colorBlue,
  animationSpeed,
  mq,
}

export const footerHeight = '65px';
export const colorBlue = '#10c7ce';
export const animationSpeed = '300ms';
export const mq = {
  xs: '(max-width: 767px)',
  sm: '(min-width: 768px) and (max-width: 1023px)',
  md: '(min-width: 1024px) and (max-width: 1279px)',
  lg: '(min-width: 1280px) and (max-width: 1439px)',
  xl: '(min-width: 1440px) and (max-width: 1919px)',
  xxl: '(min-width: 1920px)',
};
