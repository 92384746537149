import React, { Component, Fragment } from 'react';
import Head from 'next/head';
import Card from '../components/Card';

import { mq } from '../css-variables';

export default class CardsList extends Component {
  render() {
    const { language, list } = this.props;

    return (
      <Fragment>
        <Head>
          <title>
            {language === 'ru'
              ? 'Мария Добина — Портфолио'
              : 'Maria Dobina — Portfolio'
            }
          </title>
        </Head>

        <main className="grid">
          <section className="grid-items">
            {list.map((project, idx) => (
              <Card
                key={idx}
                data={project}
                language={language}
              />
            ))}
          </section>
          
          <div style={{ clear: 'both' }} />
        </main>

        {/*language=CSS*/}
        <style jsx>{`
          .grid {
            margin-top: 30px;
            padding-bottom: 30px;
            margin-left: auto;
            margin-right: auto;
            position: relative;

            @media ${mq.xs} {
              width: 250px;
            }

            @media ${mq.sm} {
              width: 560px;
            }

            @media ${mq.md} {
              width: 870px;
            }

            @media ${mq.lg} {
              width: 1180px;
            }

            @media ${mq.xl} {
              width: 1180px;
            }

            @media ${mq.xxl} {
              width: 1490px;
            }
          }

          .grid-items {
            font-size: 0;
            margin-left: -60px;
            margin-top: -60px;
          }
        `}</style>
      </Fragment>
    );
  }
}
