import React, { Component, Fragment } from 'react';
import cx from 'classnames';

import Header from '../components/Header';
import { mq, colorBlue } from '../css-variables';

let imageIdx = 0;

export default class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showState1: false,
      showState2: false,
      showState3: false,
      hideState1: false,
      hideState2: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.visible !== this.props.visible) {
      if (nextProps.visible) {
        imageIdx++;

        if (imageIdx === 3) {
          imageIdx = 0;
        }

        this.setState({
          hideState1: false,
          hideState2: false,
          showState1: true,
          showState2: false,
          showState3: false
        });

        setTimeout(() => {
          this.setState({
            showState1: true,
            showState2: true,
          });
        }, 10);

        setTimeout(() => {
          this.setState({
            showState1: true,
            showState2: true,
            showState3: true,
          });
        }, 1000);
      } else {
        this.setState({
          showState1: false,
          showState2: false,
          showState3: false,
          hideState1: true,
          hideState2: false,
        });

        setTimeout(() => {
          this.setState({
            hideState1: true,
            hideState2: true,
          });
        }, 1000);
      }
    }
  }

  render() {
    const {
      language,
      visible,
      hideAbout,
      setLanguage,
    } = this.props;

    const {
      showState1,
      showState2,
      showState3,
      hideState1,
      hideState2,
    } = this.state;

    let aboutStyle;

    if (typeof document !== 'undefined') {
      if (showState1) {
        document.body.style.overflow = 'hidden';
      }

      if (showState3) {
        document.querySelector('.site-container').style.opacity = 0;
      }

      if (hideState1) {
        document.querySelector('.site-container').style.opacity = 1;
      }

      if (hideState2) {
        document.body.style.overflow = 'auto';
      }
    }

    if (showState1 || visible) {
      aboutStyle = {
        zIndex: '10',
        display: 'block',
      };
    }

    if (hideState2) {
      aboutStyle = {
        zIndex: '-1',
        display: 'none',
      };
    }

    return (
      <section
        className={cx('about', {
          _hidden: (showState1 && !showState2) || !visible,
        })}
        style={aboutStyle}
      >
        <div className="about__container" data-img={imageIdx + 1}>
          <Header
            isAbout
            language={language}
            hideAbout={hideAbout}
            setLanguage={setLanguage}
          />

          <div className="about__content">
            {language === 'ru'
              ? <Fragment>
                  <video
                    style={{ width: 'calc(100vw - 50px)', maxWidth: 600 }}
                    controls
                    poster="/static/video.jpg"
                  >
                    <source src="/static/video.mp4" type="video/mp4" />
                  </video>

                  <h2 style={{ fontWeight: 'normal' }}>Привет!</h2>

                  <p>
                    Меня зовут Мария, я дизайнер-иллюстратор.<br />
                    Я имею большой опыт диайнерской работы, но, пожалуй, большая его часть относится к игровому маркетингу.<br />
                    Параллельно я очень увлечена работой с видео.<br />
                    На сегодняшний день я хочу значительно изменить сферу своей работы.<br />
                    Я начала обучение по специальности искусствоведение и очень хочу работать в культурной сфере.
                  </p>

                  <p>
                    Из личных качеств отмечу хороший вкус и наметанный острый глаз, постоянное развитие, любознательность и комуникабельность.
                  </p>

                  <p>
                    Я открыта любым предложениям и всегда на связи. <br />
                    Буду рада стать частью вашей команды!
                  </p>
                </Fragment>
              : <Fragment>
                  <video
                    style={{ width: 'calc(100vw - 50px)', maxWidth: 600 }}
                    controls
                    poster="/static/video.jpg"
                  >
                    <source src="/static/video.mp4" type="video/mp4" />
                  </video>

                  <h2 style={{ fontWeight: 'normal' }}>Hello!</h2>

                  <p>
                    My name is Maria, I work in Graphic and Motion design. To date, my favorites are:
                  </p>

                  <p>
                    • Illustration and animation<br />
                    • Graphic design<br />
                    • UI, Landing Pages and Digital content<br />
                    • Video Production<br />
                    • 3D animation and modeling in C4D
                  </p>

                  <p>
                    From personal qualities I will note a good taste and a trained eye,
                    constant development and curiosity, the ability to solve any task posed.
                  </p>

                  <p>
                    I will be happy to be a part of your team!
                  </p>
                </Fragment>
            }

            <p className="about__content__contacts">
              <a href="mailto:mariya.dobina@gmail.com" target="_blank">
                <img src="/static/images/contacts/icon_@_on.svg" width="42" height="42" />
              </a>
              <a href="https://t.me/MariaDobina" target="_blank">
                <img src="/static/images/contacts/icon_tg_on.svg" width="42" height="42" />
              </a>
              <a href="https://www.youtube.com/channel/UCsdN3zpXRmtcQ8fc37lKpHw" target="_blank">
                <img src="/static/images/contacts/icon_youtube.svg" width="42" height="42" />
              </a>
            </p>
          </div>
        </div>

        {/*language=CSS*/}
        <style jsx>{`
          .about {
            overflow: hidden;
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
          }

          .about img {
            display: inline-block;
          }

          .about__container {
            transform: translate3d(0, 0, 0);
            opacity: 1;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: white;
            background-position: center;
            background-size: cover;
            transition-property: transform, opacity;
            transition-duration: 700ms;
          }

          .about__container[data-img="1"] {
            background-image: url(/static/pictures/about/photo1.jpg);
          }

          .about__container[data-img="2"] {
            background-image: url(/static/pictures/about/photo2.jpg);
          }

          .about__container[data-img="3"] {
            background-image: url(/static/pictures/about/photo3.jpg);
          }

          .about__container {
            @media ${mq.xs} {
              background-image: none !important;
            }
          }

          .about._hidden .about__container {
            transform: translate3d(100%, 0, 0);
            opacity: 0;
          }

          .about__content {
            position: absolute;
            font-size: 18px;
            left: 50%;
            transform: translate(-50%, 30px);

            @media ${mq.xs} {
              top:50%;
              transform: translate(-50%, -50%);
              padding-left: 20px;
              padding-right: 20px;
            }
          }

          .about__content h2 {
            color: ${colorBlue};
            font-size: 22px;
            margin-top: 20px;
            margin-bottom: 20px;

            @media ${mq.xs} {
              font-size: 14px;
              margin-top: 10px;
              margin-bottom: 10px;
            }
          }

          .about__content p {
            margin-top: 12px;
            margin-bottom: 12px;
            line-height: 24px;
            max-width: 660px;

            @media ${mq.xs} {
              margin-top: 9px;
              margin-bottom: 9px;
              font-size: 12px;
              line-height: 15px;
            }
          }

          .about__content__photo {
            @media ${mq.xs} {
              display: block !important;
            }
          }

          .about__content__contacts {
            font-size: 0;
            margin-top: 28px !important;

            @media ${mq.xs} {
              margin-top: 12px !important;
            }
          }

          .about__content a {
            margin-right: 14px;

            @media ${mq.xs} {
              margin-right: 6px;
            }
          }

          .about__content a img {
            transition: transform 100ms ease;
            transform: scale(1);

            @media ${mq.xs} {
              width: 32px !important;
              height: 32px !important;
            }
          }

          .about__content a:hover img {
            transform: scale(1.1);
          }

          .about__content a:last-child {
            margin-right: 0 !important;
          }
        `}</style>
      </section>
    );
  }
}
