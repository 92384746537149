import React, { Component, cloneElement } from 'react';
import Head from 'next/head';

import Header from '../components/Header';
import Footer from '../components/Footer';
import About from './About';

import { mq, footerHeight } from '../css-variables';

export default class extends Component {
  state = {
    language: this.props.language || 'ru',
    isAboutVisible: false,
  };

  setLanguage = (language) => {
    this.setState({ language });
    document.cookie = `language=${language}`;
  }

  showAbout = () => {
    this.setState({ isAboutVisible: true });
  }

  hideAbout = () => {
    this.setState({ isAboutVisible: false });
  }

  render() {
    const { children } = this.props;
    const { language, isAboutVisible } = this.state;

    const childrenWithProps = cloneElement(children, { language });

    return (
      <>
        <Head>
          <meta charSet="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Lato:400,700" />
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.0.6/css/swiper.min.css" />
        </Head>

        <div className="site-container">
          <Header
            language={language}
            setLanguage={this.setLanguage}
            showAbout={this.showAbout}
          />

          {childrenWithProps}
        </div>

        <About
          language={language}
          visible={isAboutVisible}
          hideAbout={this.hideAbout}
          setLanguage={this.setLanguage}
        />

        <Footer
          language={language}
        />

        {/*language=CSS*/}
        <style jsx global>{`
          * {
            padding: 0;
            margin: 0;
            box-sizing: border-box;
          }

          html {
            min-height: 100%;
            position: relative;
          }

          body {
            background-color: white;
            font-family: 'Lato', sans-serif;
            font-size: 16px;
            line-height: 23px;
            margin-bottom: ${footerHeight};
          }

          img {
            display: block;
          }

          ._mobile-only {
            display: none;

            @media ${mq.xs} {
              display: inline-block;
            }
          }

          ._desktop-only {
            display: inline-block;

            @media ${mq.xs} {
              display: none;
            }
          }

          .site-container {
            @media ${mq.xs} {
              margin-top: 80px;
            }
          }

          .container {
            margin-left: auto;
            margin-right: auto;
            position: relative;

            @media ${mq.xs} {
              width: calc(100vw - 70px);
            }
          
            @media ${mq.sm} {
              width: 560px;
            }
          
            @media ${mq.md} {
              width: 870px;
            }
          
            @media ${mq.lg} {
              width: 1110px;
            }
          
            @media ${mq.xl} {
              width: 1180px;
            }
          
            @media ${mq.xxl} {
              width: 1490px;
            }
          }

          .svg-hover {
            display: inline-block;

            & .h1 {
              display: inline-block;
            }

            & .h2 {
              display: none;
            }

            &:hover {
              & .h1 {
                display: none
              }

              & .h2 {
                display: inline-block;
              }
            }
          }
        `}</style>
      </>
    );
  }
}
