import React, { Component, Fragment } from 'react';
import Link from 'next/link';
import { animationSpeed, colorBlue, mq } from '../css-variables';

export default class Card extends Component {
  static from = 'M250,5.74C203.07-8,136.82,2.92,74.55,39.19,44.78,56.52,19.53,77.55,0,100H250V5.74Z';
  static to = 'M250,280.63H59.172L0,280.631L250.43,280.63L250.43,280.63z';

  handleMouseEnter = () => {
    this.path.animate({ path: Card.to }, 300, (n) => n);
  }

  handleMouseLeave = () => {
    this.path.stop();
    this.path.animate({ path: Card.from }, 100, (n) => n);
  }

  componentDidMount() {
    this.path = require('snapsvg-cjs')(this.el).select('path');
  }

  render() {
    const { data, language } = this.props;

    return (
      <Fragment>
        <Link href={`/project?name=${data.url}`} as={`/projects/${data.url}`}>
          <a
            className="grid-item"
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            ref={(el) => this.el = el}
          >
            <div className="grid-item__bg" style={{ backgroundImage: `url("${data.cover}")` }} />
            <div className="grid-item__overlay" />
            <div className="grid-item__more">View</div>
            <div className="grid-item__placeholder" />
            <div className="grid-item__cover">
              <div className="grid-item__cover__svg">
                <svg viewBox="0 0 250 100">
                  <defs />
                  <path
                    style={{ fill: '#10c7ce' }}
                    d={Card.from}
                  />
                </svg>
              </div>
              <div
                className="grid-item__cover__logo"
                dangerouslySetInnerHTML={{ __html: language === 'ru'
                  ? data.mainpage_title_ru
                  : data.mainpage_title
                }}
              />
              <div
                className="grid-item__cover__title"
                dangerouslySetInnerHTML={{ __html: language === 'ru'
                  ? data.mainpage_description_ru
                  : data.mainpage_description
                }}
              />
            </div>
          </a>
        </Link>

        {/*language=CSS*/}
        <style jsx>{`
          .grid-item {
            position: relative;
            width: 250px;
            height: 300px;
            display: block;
            float: left;
            margin-left: 60px;
            margin-top: 60px;
          }

          .grid-item__placeholder {
            background-color: ${colorBlue};
            height: 45px;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
          }

          .grid-item__bg {
            background-size: cover;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
          }

          .grid-item__more {
            position: absolute;
            width: 66px;
            height: 26px;
            border: 2px solid #fff;
            font-size: 15px;
            text-transform: uppercase;
            color: #fff;
            border-radius: 26px;
            line-height: 22px;
            text-align: center;
            left: 50%;
            top: 50%;
            margin-left: -33px;
            margin-top: -13px;
            opacity: 0;
            transition: opacity ${animationSpeed} ease;
            background-color: rgba(0, 0, 0, .1);
          }

          .grid-item__overlay {
            opacity: 1;
            position: absolute;
            transition: opacity ${animationSpeed} ease;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;

            @media ${mq.xs} {
              display: none !important;
            }
          }

          .grid-item__cover {
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            height: 145px;
          }

          .grid-item__cover__logo {
            position: absolute;
            left: 0;
            width: 100%;
            bottom: 14px;
            font-size: 14px;
            color: #fff;
            text-align: center;
            line-height: 16px;
            font-weight: bold;
          }

          .grid-item__cover__logo._svg {
            & img {
              display: block;
              vertical-align: middle;
              margin: 0 auto;
            }
          }

          .grid-item__cover__title {
            position: absolute;
            bottom: 56px;
            right: 20px;
            color: #fff;
            font-size: 16px;
            line-height: 24px;
            text-align: right;
            opacity: 1;
            transition: opacity ${animationSpeed} ease;
          }

          .grid-item:hover .grid-item__overlay {
            opacity: 0;
          }

          .grid-item:hover .grid-item__cover__title {
            opacity: 0;
          }

          .grid-item:hover .grid-item__more {
            opacity: 1;
          }
        `}</style>

        {/*language=CSS*/}
        <style jsx global>{`
          .grid-item__cover__logo img {
            display: inline-block;
            vertical-align: -1px;
          }
        `}</style>
      </Fragment>
    );
  }
}
